<template>
  <div>
    <Navbar />
    <v-container class="bots" id="bots">
      <h1>Running Bots</h1>
      <v-card>
        <v-card-title class="mb-3"><h3>Current Bots</h3></v-card-title>
        <v-card-text>
          <v-layout row wrap justify-center>
            <v-flex xs12 md5 lg3 v-for="(bot, index) in bots" :key="index">
              <v-card :loading="loading" class="mx-sm-3 mb-5">
                <v-img height="200" src="/img/bots/bot1.jpg"></v-img>
                <v-card-title>{{ bot.title }}</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Exchange: {{ bot.exchangeName }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Algorithm: {{ bot.algorithm }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="bot.subaccountName">
                        <v-list-item-content>
                          <v-list-item-title v-if="bot.subaccountName != null">
                            Subaccount: {{ bot.subaccountName }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Currency: {{ bot.asset }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Capital: {{ bot.balance }} €
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Trade interval: {{ bot.timeInterval }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Created: {{ bot.createdAt.split("T")[0] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    <v-divider class="mx-4"></v-divider>
                    <div class="text-center mt-3">
                      <v-btn
                        text
                        color="primary"
                        class="mb-3"
                        :to="`/performance/${bot.id}`"
                      >
                        <span>Performance anzeigen</span>
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                      <v-divider class="mx-4"></v-divider>
                      <BotDeletePopUp :botId="bot.id" />
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-divider class="mx-4 my-3"></v-divider>
          <div class="text-center">
            <v-btn text color="primary" class="mb-3 mt-5" href="#bots">
              <span>to the top</span>
              <v-icon right> mdi-arrow-up </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import BotDeletePopUp from "@/components/BotDeletePopUp";

export default {
  name: "Home",
  data() {
    return {
      bots: [],
      loading: true,
    };
  },
  components: {
    BotDeletePopUp,
    Navbar,
  },
  methods: {
    async fetch_bots(userId) {
      const token = this.$store.getters["user/getTokenValue"];
      try {
        let request = await fetch(
          `${process.env.VUE_APP_ALL_BOTS_URL}${userId}`,
          {
            method: "GET",
            withCredentials: true,
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        if (request.status === 200 || request.status === 401) {
          return await this.formatData(request);
        } else {
          console.log("ERROR");
          //await this.formatData(request);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async formatData(request) {
      const text = await request.text();
      const data = await JSON.parse(text);
      return data;
    },
  },
  created: async function () {
    const bots = await this.fetch_bots(this.$store.state.user.user.id);
    this.loading = false;
    this.bots = bots.user_bots;
  },
};
</script>
